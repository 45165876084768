<template>
  <div>
    <b-overlay
      :show="item === null"
      rounded="sm"
    >
      <b-card v-if="item">
        <b-form>
          <b-row>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('admin.labels.role')"
                label-for="role"
                :state="errors && errors.role ? false : null"
              >
                <b-form-select
                  v-model="item.role"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="roleOptions"
                  input-id="role"
                />

                <b-form-invalid-feedback v-if="errors && errors.role">
                  {{ errors.role[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('admin.labels.locale')"
                label-for="locale"
              >
                <v-select
                  v-model="item.locale"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="languagesSelect"
                  input-id="locale"
                  :reduce="val => val.value"
                  :clearable="false"
                />
                <b-form-invalid-feedback v-if="errors && errors.locale">
                  {{ errors.locale[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('admin.labels.commission')"
                label-for="commission"
              >
                <b-form-input
                  id="commission"
                  v-model="item.commission"
                  type="number"
                  step="0.1"
                  min="0"
                  max="99"
                  :state="errors && errors.commission ? false : null"
                />

                <b-form-invalid-feedback v-if="errors && errors.commission">
                  {{ errors.commission[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('admin.labels.status')"
                label-for="status"
                :state="errors && errors.status ? false : null"
              >
                <v-select
                  v-model="item.status"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="userStatusOptions"
                  input-id="status"
                  :clearable="false"
                  class="w-100"
                  :reduce="val => val.value"
                />

                <b-form-invalid-feedback v-if="errors && errors.status">
                  {{ errors.status[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('admin.labels.first_name')"
                label-for="first_name"
              >
                <b-form-input
                  id="first_name"
                  v-model="item.first_name"
                  :state="errors && errors.first_name ? false : null"
                />

                <b-form-invalid-feedback v-if="errors && errors.first_name">
                  {{ errors.first_name[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('admin.labels.last_name')"
                label-for="last_name"
              >
                <b-form-input
                  id="last_name"
                  v-model="item.last_name"
                  :state="errors && errors.last_name ? false : null"
                />

                <b-form-invalid-feedback v-if="errors && errors.last_name">
                  {{ errors.last_name[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('admin.labels.email')"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  v-model="item.email"
                  :state="errors && errors.email ? false : null"
                />

                <b-form-invalid-feedback v-if="errors && errors.email">
                  {{ errors.email[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('admin.labels.phone')"
                label-for="phone"
              >
                <b-form-input
                  id="phone"
                  v-model="item.phone"
                  :state="errors && errors.phone ? false : null"
                />

                <b-form-invalid-feedback v-if="errors && errors.phone">
                  {{ errors.phone[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('admin.labels.password')"
                label-for="account-password"
              >
                <b-form-input
                  v-model="item.password"
                  name="password"
                  :placeholder="$t('admin.labels.password')"
                  :state="errors && errors.password ? false : null"
                />
                <b-form-invalid-feedback v-if="errors && errors.password">
                  {{ errors.password[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

          </b-row>
        </b-form>
      </b-card>

      <b-tabs v-if="item">
        <b-tab
          v-for="language in languages"
          :key="language.code"
        >
          <template #title>
            <b-img
              :src="require('@/assets/images/flags/' + language.code + '.svg')"
              height="16"
              width="16"
              class="mr-1"
            />
            <span class="d-none d-sm-inline">{{ language.title }}</span>
          </template>

          <div>
            <b-row>
              <b-col
                v-for="st in ['base', 'verify', 'free']"
                :key="st"
                cols="12"
                md="4"
              >
                <b-card :title="`${$t(`client.account.labels.status.${st}.title`)} - limit`">
                  <b-row
                    v-for="(limit, index) in item.statuses_limits[language.code][st]"
                    :id="'limit' + index + language.code"
                    :key="'limit' + index + language.code"
                  >
                    <b-col cols="12" class="mb-1">
                      <b-form-input
                        :placeholder="$t('admin.labels.title')"
                        v-model="limit.t"
                      />
                    </b-col>
                    <b-col cols="8">
                      <b-form-input
                        :placeholder="$t('admin.labels.value')"
                        v-model="limit.v"
                      />
                    </b-col>
                    <b-col cols="4">
                      <b-button
                        variant="outline-danger"
                        block
                        @click="removeStatusLimit(language.code, st, index)"
                      >
                        <feather-icon
                          icon="XIcon"
                          class="mr-25"
                        />
                      </b-button>
                    </b-col>
                    <b-col cols="12">
                      <hr />
                    </b-col>
                  </b-row>

                  <b-button
                    variant="outline-success"
                    block
                    @click="addStatusLimit(language.code, st)"
                  >
                    <feather-icon
                      icon="PlusIcon"
                    />
                  </b-button>
                </b-card>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                v-for="st in ['base', 'verify', 'free']"
                :key="st"
                cols="12"
                md="4"
              >
                <b-card :title="`${$t(`client.account.labels.status.${st}.title`)} - info`">
                  <b-row
                    v-for="(info, index) in item.statuses_info[language.code][st]"
                    :id="'info' + index + language.code"
                    :key="'info' + index + language.code"
                  >
                    <b-col cols="12" class="mb-1">
                      <b-form-input
                        :placeholder="$t('admin.labels.title')"
                        v-model="info.t"
                      />
                    </b-col>
                    <b-col cols="8">
                      <b-form-input
                        :placeholder="$t('admin.labels.value')"
                        v-model="info.v"
                      />
                    </b-col>
                    <b-col cols="4">
                      <b-button
                        variant="outline-danger"
                        block
                        @click="removeStatusInfo(language.code, st, index)"
                      >
                        <feather-icon
                          icon="XIcon"
                          class="mr-25"
                        />
                      </b-button>
                    </b-col>
                    <b-col cols="12">
                      <hr />
                    </b-col>
                  </b-row>
                  <b-button
                    variant="outline-success"
                    block
                    @click="addStatusInfo(language.code, st)"
                  >
                    <feather-icon
                      icon="PlusIcon"
                    />
                  </b-button>
                </b-card>
              </b-col>
            </b-row>

          </div>
        </b-tab>
      </b-tabs>

      <b-row v-if="item">
        <b-col cols="12">
          <b-card>
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="onSubmit"
            >
              {{ $t('admin.buttons.save') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              :to="{ name: 'users-index' }"
            >
              {{ $t('admin.buttons.cancel') }}
            </b-button>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import GlobalMixin from '@/mixins/GlobalMixin'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { required, alphaNum, email } from '@validations'

export default {
  mixins: [GlobalMixin],
  data() {
    return {
      required,
      alphaNum,
      email,
      roleOptions: [],
      languages: [],
      languagesSelect: [],
      item: null,
    }
  },
  async beforeCreate() {
    await this.$http('/api/languages')
      .then(response => {
        this.languages = response.data.data
        this.languagesSelect = this.$options.filters.transformForVSelect(response.data.data, 'code', 'title')
      })
    await this.$http.get('/api/roles').then(response => {
      const { data } = response.data

      this.roleOptions = this.$options.filters.transformForBSelect(data, 'name', 'title')
    })

    await this.$http.get(`/api/users/${router.currentRoute.params.id}`)
      .then(response => {
        const { data } = response.data

        this.item = this.transformData(data)
      })
      .catch(error => {
        if (error.response.status === 404) {
          this.item = undefined
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.not_found'),
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          router.replace({ name: 'users-index' })
        }
      })
  },
  methods: {
    async onSubmit() {
      const data = {
        first_name: this.item.first_name,
        last_name: this.item.last_name,
        email: this.item.email,
        phone: this.item.phone,
        role: this.item.role,
        locale: this.item.locale,
        password: this.item.password,
        commission: this.item.commission,
        status: this.item.status,
        statuses_limits: this.item.statuses_limits,
        statuses_info: this.item.statuses_info,
      }

      this.$http.put(`/api/users/${this.item.id}`, data)
        .then(() => {
          router.replace({ name: 'users-index' })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
        })
    },
    transformData(item) {
      return {
        id: item.id,
        first_name: item.first_name,
        last_name: item.last_name,
        email: item.email,
        phone: item.phone,
        role: item.roles[0].name,
        locale: item.locale,
        commission: item.commission,
        status: item.status,
        statuses_limits: item.statuses_limits,
        statuses_info: item.statuses_info,
      }
    },
    addStatusLimit(l, s) {
      this.item.statuses_limits[l][s].push({
        t: '',
        v: '',
      })
    },
    removeStatusLimit(l, s, i) {
      this.item.statuses_limits[l][s].splice(i, 1)
    },
    addStatusInfo(l, s) {
      this.item.statuses_info[l][s].push({
        t: '',
        v: '',
      })
    },
    removeStatusInfo(l, s, i) {
      this.item.statuses_info[l][s].splice(i, 1)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
